.page.dashboard {
  color: white;
  padding-bottom: 10%;
  * {
    img {
      max-width: 100%;
    }
  }
  .error-message {
    width: 100%;
    padding: 10px 20px;
    background: $primary-error-red;
    color: white;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title-wrapper {
      .title {
        text-align: center;
      }
    }
    .global-signout-button {
      margin-bottom: 10px;
    }
    button {
      padding: 10px 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      color: white;
      background: $grey-dark;
      transition: 100ms ease all;
      &:hover {
        background: $primary-darker;
      }
    }
    form {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      max-width: 80vw;
      gap: 10px;
      padding: 20px;
      border-radius: 5px;
      outline: 1px solid #565656;
      background: $grey;
      color: #ccc;
      input,
      textarea {
        width: 100%;
        border-radius: 5px;
        color: #ccc;
        padding: 10px 10px;
        background: $grey-dark;
        resize: vertical;
        outline: 1px solid transparent;
        border: none;
        &:focus,
        &:target,
        &:checked {
          outline: 1px solid #565656;
        }
      }
      .sub-set {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #565656;
        }
      }
      .add-post {
        width: 100%;
        margin-top: 10px;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 1px;
          left: 50%;
          transform: translateX(-50%);
          top: -10px;
          background: #565656;
          position: absolute;
        }
      }
      .markdown-editor {
        width: 100%;
      }
    }
    .posts-list {
      width: 100%;
      max-width: 80vw;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      .post {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        outline: 1px solid #565656;
        padding: 10px 20px;
        gap: 10px;
        .global-delete {
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          background: $primary-error-red;
          transition: 100ms ease all;
          &:hover {
            background: $primary-error-red-darker;
          }
        }
      }
    }
  }
}
