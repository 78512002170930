$yellow: #e3b23c;
$yellow-light: #ffeb3b;
$yellow-dark: #ffc107;
$purple: #5448c8;
$purple-light: #ae7eec;
$purple-medium: #8c6bb8;
$purple-dark: #704b97;
$red: #ff3333;
$red-dark: #990000;
$green: #4caf50;
$green-dark: darken(#4caf50, 5%);
$blue: #3f51b5;
$blue-light: #2196f3;
$blue-medium: #03a9f4;
$blue-dark: #00bcd4;
$orange: #ff9800;
$orange-dark: #ff5722;
$teal: #009688;
$teal-light: #4db6ac;
$orange-transparent: rgba(255, 179, 0, 0.4);
$orange-dark-transparent: rgba(191, 135, 4, 0.4);
$orange-2-transparent: rgba(255, 99, 0, 0.2);
$orange-2-dark-transparent: rgba(207, 85, 8, 0.2);
$red-transparent: rgba(245, 10, 10, 0.3);
$red-dark-transparent: rgba(219, 9, 9, 0.3);
$green-transparent: rgba(57, 237, 12, 0.2);
$green-dark-transparent: rgba(51, 212, 11, 0.2);
$grey: rgb(50, 50, 50);
$grey-dark: rgba(35, 35, 35, 1);
$white-0-1: rgba(255, 255, 255, 0.1);