.page.home {
    color: white;
    padding-bottom: 10%;
    .wrapper {
        .title-wrapper {
            .title {
                text-align: center;
            }
        }
        .actions {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            .action {
                padding: 10px;
            }
        }
    }
}