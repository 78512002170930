.page.signin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  padding-top: 10%;
  padding-bottom: 10%;
  .error-message {
    width: 100%;
    padding: 10px 20px;
    background: $primary-error-red;
    color: white;
  }
  button {
    padding: 10px 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background: $grey-dark;
    transition: 100ms ease all;
    &:hover {
      background: $primary-darker;
    }
  }
  .title-wrapper {
    .title {
      text-align: center;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 80vw;
    gap: 10px;
    padding: 20px;
    border-radius: 5px;
    outline: 1px solid #565656;
    background: $grey;
    color: #ccc;
    input {
      width: 100%;
      border-radius: 5px;
      color: #ccc;
      padding: 10px 10px;
      background: $grey-dark;
      resize: vertical;
      outline: 1px solid transparent;
      border: none;
      &:focus,
      &:target,
      &:checked {
        outline: 1px solid #565656;
      }
    }
  }
}
