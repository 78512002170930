@import "./preload-connector";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  a {
    color: $link-color;
    cursor: pointer;
    transition: 100ms ease all;
    text-decoration: none;
    &:hover {
      color: $link-color-hover;
      text-decoration: none;
    }
  }
}

:root {
  font-family: "Hanken Grotesk", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: $primary-bg;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbar-track-bg;
  }
}

@import "./page-connector";